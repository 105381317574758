import dashboard from "./menu/dashboard";
import administrator from "./menu/administrator";
import rolePermission from "./menu/rolePermission";
import company from "./menu/company";
import senior from "./menu/senior";
import master from "./menu/master";
import agent from "./menu/agent";
import player from "./menu/player";
import assistant from "./menu/assistant";
import transaction from "./menu/transaction";
import balanceReport from "./menu/balanceReport";
import baccaratBalanceReport from "./menu/baccaratBalanceReport";
import parley from "./menu/parley";
import liveBetting from "./menu/liveBetting";
import parleyReport from "./menu/parleyReport";
import requestDeposit from "./menu/requestDeposit";
import requestWithdraw from "./menu/requestWithdraw";
import betSetting from "./menu/betSetting";
import cockBalanceReport from "./menu/cockBalanceReport";
import baccaratLive from "./menu/baccaratLive";
import baccaratResult from "./menu/baccaratResult";
import slotBalanceReport from "./menu/slotBalanceReport";

export default [
  ...dashboard,
  ...administrator,
  ...rolePermission,
  ...liveBetting,
  ...baccaratResult,
  ...baccaratLive,
  ...parley,
  ...company,
  ...senior,
  ...master,
  ...agent,
  ...player,
  ...assistant,
  ...transaction,
  ...balanceReport,
  ...cockBalanceReport,
  ...baccaratBalanceReport,
  ...slotBalanceReport,
  ...parleyReport,
  ...requestDeposit,
  ...requestWithdraw,
  ...betSetting,
]
